/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamStandaloneDirektBanner as ResolvedStandaloneDirektBanner } from 'sites/svenskdam/components/standalone/DirektBanner';

export const DirektBanner: typeof ResolvedStandaloneDirektBanner = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneDirektBanner {...props} />
        </ErrorBoundary>
    );
});

export type DirektBannerProps = PropsFromComponent<typeof DirektBanner>;

/** @deprecated Use slot architecture instead */
export const DirektBannerWith = (extras: DynamicStandaloneExtras): typeof DirektBanner => {
    return function DirektBanner(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'DirektBanner');
        return <ResolvedStandaloneDirektBanner {...mergeProps({ options: { theme } }, props)} />;
    }
}
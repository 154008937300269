import { Blip } from '@/components/Blip';
import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent } from '@/types/component';

export const SvenskdamStandaloneDirektBanner: StandaloneComponent = () => {
  const [{ type }] = useAppState();

  const withFrontPageClasses = type === 'front' ? 'sm:mx-auto sm:w-3/4 sm:px-15' : '';

  return (
    <div className={`flex flex-col gap-2 ${withFrontPageClasses}`}>
      <span className="text-headline-3xs flex w-full items-center gap-3 after:h-[2px] after:flex-grow after:bg-primary-700">
        DIREKTRAPPORTERING
      </span>

      <Link
        href="/direkt"
        options={{ className: 'no-underline' }}
        content={
          <div className="flex flex-wrap items-center gap-1 bg-gray-100 p-3 text-black text-headline-xs">
            <Blip options={{ className: 'mr-1 mt-0.5' }} />
            <span className="text-primary-700">Svensk Dam Direkt</span>
            <Icon name="check" />
            <span>Chatta med oss här</span>
            <Icon name="check" />
            <span>Allt nytt om kungligt och nöje</span>
          </div>
        }
      />
    </div>
  );
};

import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
  base: `top-4.5`,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[63px] md:h-[45px] lg:mx-auto lg:w-col-lg-8`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: `h-[63px] md:h-[45px] lg:mx-auto lg:w-col-lg-8`,
        item: `data-[show-blip=false]:pl-4`,
        headline: `text-headline-2xs md:text-headline-xs`,
        label: `text-headline-2xs md:text-headline-xs`,
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
